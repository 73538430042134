
export const SET_USER_ROLE = 'SET_USER_ROLE';

export const SET_USER_ID='SET_USER_ID';

export const SET_USER_NAME='SET_USER_NAME';

export const SET_JWTTOKEN='SET_JWTTOKEN';

export const SET_O_PHOTO='SET_O_PHOTO';

export const SET_STUDENT='SET_STUDENT';

export const SET_PHONE='SET_PHONE';

export const SET_SPACE_BUCKS='SET_SPACE_BUCKS';

export const SET_NEW_REGISTER='SET_NEW_REGISTER';

export const LOGOUT='LOGOUT';