export const SET_Career = 'SET_Career';

export const SET_Type = 'SET_Type';

export const SET_Steam = 'SET_Steam';

export const SET_Report = 'SET_Report';

export const SET_Webinar = 'SET_Webinar';

export const LOGOUT='LOGOUT';